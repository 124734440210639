import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withI18n, Trans } from '@lingui/react';
// import get from 'lodash/get';

import DropdownListFilterWrapper from './utils/DropdownListFilterWrapper';
import SectorsTree from '../containers/SectorsTree';
import { actions } from '../actions/filtersActions';
import { actions as sectorsActions } from '../actions/sectorsActions';
import * as constants from '../constants';
import { getOptionLabelLocalized } from '../intl-helpers';
import AttributeNumericWrapper from './AttributeNumericWrapper';
export class Sidebar extends Component {
    render() {
        const {
            i18n,
            classificationFilter,
            brandFilter,
            isBrandFixed,
            // goingOutOfProductionFilter,
            optionalsFilter,
            // outOfProductionFilter,
            replacementsFilter,
            statusFilter,
            priceRangeFilter,
            height,
            language,
            showPriceRangeFilter
        } = this.props;

        return (
            <div>
                {isBrandFixed !== true && (
                    <Fragment>
                        {/* <h6 className="text-uppercase text-primary">
                            <Trans id="choose:by:brand" />
                        </h6> */}
                        <DropdownListFilterWrapper
                            filter={brandFilter}
                            placeholder={i18n._('filter:brand')}
                            disabled={isBrandFixed}
                            itemToString={getOptionLabelLocalized(language)}
                            onChange={item =>
                                this.props.dispatch(actions.changeBrandFilterValue(item))
                            }
                        />
                    </Fragment>
                )}
                {statusFilter.options.count() > 0 && (
                    <DropdownListFilterWrapper
                        filter={statusFilter}
                        placeholder={i18n._('filter:status')}
                        itemToString={getOptionLabelLocalized(language)}
                        onChange={item =>
                            this.props.dispatch(
                                actions.setFilterValue(constants.STATUS_FILTER, item)
                            )
                        }
                        multiple={true}
                    />
                )}
                {statusFilter.options.count() === 0 && <div style={{ height: '32px' }} />}
                <div className="columns">
                    <div className="column col-6">
                        <div className="form-compact">
                            <div className="form-group">
                                <label className="form-checkbox">
                                    <input
                                        type="checkbox"
                                        checked={optionalsFilter.value}
                                        disabled={!optionalsFilter.enabled}
                                        onChange={e =>
                                            this.props.dispatch(
                                                actions.setFilterValue(
                                                    constants.OPTIONALS_FILTER,
                                                    e.target.checked
                                                )
                                            )
                                        }
                                    />
                                    <i className="form-icon" /> <Trans id="optionals" />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="column col-6">
                        <div className="form-compact">
                            <div className="form-group">
                                <label className="form-checkbox">
                                    <input
                                        type="checkbox"
                                        checked={replacementsFilter.value}
                                        disabled={!replacementsFilter.enabled}
                                        onChange={e =>
                                            this.props.dispatch(
                                                actions.setFilterValue(
                                                    constants.REPLACEMENTS_FILTER,
                                                    e.target.checked
                                                )
                                            )
                                        }
                                    />
                                    <i className="form-icon" /> <Trans id="replacements" />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                {showPriceRangeFilter && (
                    <React.Fragment>
                        <div className="divider" />
                        <AttributeNumericWrapper
                            label="Prezzo min/max"
                            rangeSuffix="€"
                            freeStep={true}
                            min={priceRangeFilter.min}
                            max={priceRangeFilter.max}
                            start={priceRangeFilter.value[0]}
                            end={priceRangeFilter.value[1]}
                            isActive={
                                priceRangeFilter.value[0] !== 0 || priceRangeFilter.value[1] !== 0
                            }
                            attribute={{}}
                            onLoad={() => {}}
                            onClear={() =>
                                this.props.dispatch(
                                    actions.setFilterValue(constants.PRICE_RANGE_FILTER, [0, 0])
                                )
                            }
                            onChange={(_, range) =>
                                this.props.dispatch(
                                    actions.setFilterValue(constants.PRICE_RANGE_FILTER, range)
                                )
                            }
                        />
                    </React.Fragment>
                )}
                <div className="divider" />
                <h6 className="text-uppercase text-primary">
                    <Trans id="choose:by:taxonomy" />
                </h6>
                <DropdownListFilterWrapper
                    filter={classificationFilter}
                    placeholder={i18n._('filter:classification')}
                    clearable={false}
                    itemToString={getOptionLabelLocalized(language)}
                    onChange={item =>
                        this.props.dispatch(sectorsActions.setClassificationValue(item))
                    }
                />
                <SectorsTree
                    sidebarHeight={height}
                    // type={classificationFilter.value.value}
                />
            </div>
        );
    }
}

Sidebar.propTypes = {
    classificationFilter: PropTypes.object.isRequired,
    brandFilter: PropTypes.object.isRequired,
    i18n: PropTypes.object,
    isBrandFixed: PropTypes.bool,
    optionalsFilter: PropTypes.object.isRequired,
    replacementsFilter: PropTypes.object.isRequired,
    statusFilter: PropTypes.object.isRequired,
    showPriceRangeFilter: PropTypes.bool.isRequired,
    height: PropTypes.number,
    language: PropTypes.string
};

export default withI18n()(Sidebar);
