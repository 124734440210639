import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, withI18n } from '@lingui/react';
import isObject from 'lodash/isObject';
import cx from 'classnames';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import isString from 'lodash/isString';
// import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import get from 'lodash/get';
// import isUndefined from 'lodash/isUndefined';
import filesize from 'filesize';
import NewWindow from 'react-new-window';
import { parse, format } from 'date-fns';

import LimitText from './utils/LimitText';
import SortableTableHeader from './utils/SortableTableHeader';
import * as constants from '../constants';
import { fetchItemByBrandAndCode } from '../api';
import { getLocaleFromLanguageCode } from '../intl-helpers';
import { ArticleDetailsModal } from './ArticleDetailsModal';
// import ArticleDataChooserModal from './ArticleDataChooserModal';

// const umFields = ['unita_ordine', 'unita_contenuta'/*, 'id_um_confezione_1'*/];

// const classImageLabels = {
//     [constants.]
// }

const TYPE_ATTR = 'type';
const CODICE_ARTICOLO_ATTR = 'codice_articolo';

const IGNORED_KEYS = [
    'nome_produttore',
    'codice_articolo_produttore',
    'descrizione_articolo_produttore',
    'descrizione_corta_produttore'
];

function getFileName(fileName) {
    if (fileName !== null) {
        return fileName.split(/(\\|\/)/g).pop();
    }
}

function getArticleDescription(data, language) {
    const identificazione = find(data, { property: 'identificazione' });

    if (!identificazione) {
        return '-';
    }

    const description = find(identificazione.children, { property: 'descrizione_articolo' });

    if (!description) {
        return '-';
    }

    return get(description, `value.${getLocaleFromLanguageCode(language)}`, '-');
}

function CorrelatiRow({ article, language }) {
    const [description, setDescription] = useState('...');
    const [articleData, setArticleData] = useState(null);
    const [showCorrelato, setShowCorrelato] = useState(false);

    useEffect(() => {
        fetchItemByBrandAndCode(article.sigla_marca, article.codice_articolo)
            .then(res => {
                setArticleData(res.data);
                setDescription(getArticleDescription(res.data.children, language));
            })
            .catch(() => {
                setDescription('-');
            });
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {showCorrelato && (
                <NewWindow
                    title={description}
                    features={{
                        width: 1024,
                        height: 768
                    }}
                    onUnload={() => {
                        setTimeout(() => {
                            setShowCorrelato(false);
                        }, 500);
                    }}
                >
                    <ArticleDetailsModal
                        article={{ id: articleData ? articleData.id : null }}
                        isModal={false}
                        //onClose={() => setShowCorrelato(false)}
                    />
                </NewWindow>
            )}
            <tr>
                <td>{article.type}</td>
                <td>
                    <span
                        style={{
                            cursor: articleData ? 'pointer' : 'default',
                            color: articleData ? '#3692f9' : 'inherit',
                            fontWeight: articleData ? 'bold' : 'normal'
                        }}
                        onClick={() => articleData && setShowCorrelato(true)}
                    >
                        {article.codice_articolo}
                    </span>
                </td>
                <td>
                    {description}
                    {/* {articleData ? (
                        <a
                            href={`/dp/app?showArticle=${articleData.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {description}
                        </a>
                    ) : (
                        description
                    )} */}
                </td>
                <td>{article.qty}</td>
            </tr>
        </>
    );
}

export class ItemDataCategoryTable extends Component {
    state = {
        sortBy: null,
        sortDirection: 'asc'
    };

    getFilteredAttrs(section) {
        // console.log(section);

        return section.filter(a => !includes(IGNORED_KEYS, a.key));
    }

    renderAttrValue({ key, value }) {
        // console.log(key, value);
        // if (includes(umFields, key)) {
        //     return c.UM_LABELS[value];
        // }

        // if (key === 'status') {
        //     return capitalize(c.STATUS_LABELS[value]);
        // }

        // if (key === 'data_inizio_prezzo') {
        //     return format(value, 'DD/MM/YYYY');
        // }

        // TODO: rendere piu robusto, non e detto che se é un oggetto sia sempre una traduzione
        if (isObject(value)) {
            return JSON.stringify(value);
        }

        const regex = new RegExp(
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/
        );

        if (isString(value) && value.match(regex)) {
            return (
                <a href={value} target="_blank" rel="noopener noreferrer">
                    {value}
                </a>
            );
        }
        // console.warn(value);

        return value;
    }

    getFilteredAssetsAttributes() {
        const { attributes, selectedFilter, extFilter } = this.props;

        if (selectedFilter === 'all' && extFilter === 'all' && this.state.sortBy === null) {
            return attributes;
        }

        let filteredAttributes = attributes;

        if (selectedFilter !== 'all') {
            filteredAttributes = filter(attributes, attribute => {
                const klasses = attribute.asset_classes.map(k => k.code);

                return includes(klasses, selectedFilter);
            });
        }

        if (extFilter !== 'all') {
            filteredAttributes = filter(filteredAttributes, attribute => {
                return attribute.ext === extFilter;
            });
        }

        if (this.state.sortBy === null) {
            return filteredAttributes;
        }

        const sorted = sortBy(filteredAttributes, this.state.sortBy);

        if (this.state.sortDirection === 'asc') {
            return sorted;
        }

        return sorted.reverse();
    }

    getFilteredArticoliCorrelati() {
        const { attributes, selectedFilter } = this.props;

        let filtered = attributes;

        if (selectedFilter !== 'all') {
            filtered = filter(filtered, a => {
                return a.type === selectedFilter;
            });
        }

        /*if (selectedFilter !== 'all') {
            // TODO: rendere piu robusto?
            filtered = filter(attributes, a => {
                const type = find(a, { key: TYPE_ATTR });

                return type.value === selectedFilter;
            });
        }

        const flatList = filtered.map(f => {
            let item = {};

            f.forEach(p => {
                item[p.key] = p.value;
            });

            return item;
        });*/

        if (this.state.sortBy === null) {
            return sortBy(filtered, [TYPE_ATTR, CODICE_ARTICOLO_ATTR]);
        }

        const toSortBy =
            this.state.sortBy !== TYPE_ATTR ? this.state.sortBy : [TYPE_ATTR, CODICE_ARTICOLO_ATTR];

        const sorted = sortBy(filtered, toSortBy);

        if (this.state.sortDirection === 'asc') {
            return sorted;
        }

        return sorted.reverse();
    }

    getSorting() {
        return this.state;
    }

    toggleSort = field => {
        this.setState(prevState => {
            return {
                sortBy: field,
                sortDirection:
                    prevState.sortBy !== field
                        ? 'asc'
                        : prevState.sortDirection === 'asc'
                        ? 'desc'
                        : 'asc'
            };
        });
    };

    renderEmpty() {
        return (
            <div className="empty">
                <Trans id="no:information:available" />
            </div>
        );
    }

    renderTextList() {
        const { category } = this.props;

        const isCorrelati = category.key === constants.SECTION_KEY_CORRELATI;

        return isCorrelati ? this.renderCorrelatiList() : this.renderSimpleTextList();
    }

    renderSimpleTextList() {
        const { attributes, type } = this.props;

        const isRepeatable = type === constants.SECTION_TYPE_REPEATABLE;
        const sections = isRepeatable ? attributes : [attributes];

        // console.warn(sections);

        return sections.map((section, index) => {
            // console.warn(this.getFilteredAttrs(section));

            return (
                <table
                    key={index}
                    className={cx(
                        'table table-striped table-striped-alt table-hover table-article-info',
                        {
                            'table-articolo-info-multiple': isRepeatable
                        }
                    )}
                >
                    <tbody>
                        {this.getFilteredAttrs(section).map((attr, i) => {
                            const value = this.renderAttrValue(attr);

                            /*if (value === null || isUndefined(value) || value === '') {
                                return null;
                            }*/

                            return (
                                <tr key={i}>
                                    <td>
                                        {attr.label}
                                        {isRepeatable && i === 0 ? ` ${index + 1}` : ''}
                                    </td>
                                    <td>
                                        <strong dangerouslySetInnerHTML={{ __html: value }} />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            );
        });
    }

    renderCorrelatiList() {
        const rows = this.getFilteredArticoliCorrelati();

        return (
            <table className={'table table-striped table-striped-alt table-hover table-dense'}>
                <thead>
                    <tr>
                        <SortableTableHeader
                            attr={TYPE_ATTR}
                            onClick={this.toggleSort}
                            sorting={this.getSorting()}
                            headerStyle={{ width: '150px' }}
                        >
                            <Trans id="type" />
                        </SortableTableHeader>
                        <SortableTableHeader
                            attr={CODICE_ARTICOLO_ATTR}
                            onClick={this.toggleSort}
                            sorting={this.getSorting()}
                            headerStyle={{ width: '150px' }}
                        >
                            <Trans id="article:code" />
                        </SortableTableHeader>
                        <th>
                            <Trans id="article:description" />
                        </th>
                        <SortableTableHeader
                            attr="qty"
                            onClick={this.toggleSort}
                            sorting={this.getSorting()}
                            headerStyle={{ width: '100px' }}
                        >
                            <Trans id="quantity" />
                        </SortableTableHeader>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((attr, i) => (
                        <CorrelatiRow article={attr} language={this.props.i18n._language} key={i} />
                    ))}
                </tbody>
            </table>
        );
    }

    renderFileList() {
        return (
            <table className="table table-striped table-striped-alt table-hover table-dense">
                <thead>
                    <tr>
                        <th style={{ width: '312px' }}>
                            <Trans id={this.props.category.classLabel} />
                        </th>
                        <th style={{ width: '200px' }}>
                            <Trans id="file:name" />
                        </th>
                        <th style={{ width: '380px' }}>
                            <Trans id="description" />
                        </th>
                        <th style={{ width: '120px' }} className="text-center">
                            <Trans id="download" />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {this.getFilteredAssetsAttributes().map((attr, i) => (
                        <tr key={i}>
                            <td>
                                <LimitText limit={30} text={attr.label} textClass="tooltip-right" />
                            </td>
                            {/* <td>{attr.ext}</td>
                            <td>
                                {attr.size
                                    ? filesize(attr.size, {
                                          separator: ',',
                                          base: 10
                                      })
                                    : '-'}
                            </td> */}
                            <td>
                                <LimitText limit={40} text={getFileName(attr.filename)} />
                            </td>
                            <td>
                                <LimitText limit={100} text={attr.description} />
                            </td>
                            <td className="text-center">
                                <a
                                    className="btn btn-action btn-link"
                                    title="Download"
                                    href={attr.src}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="icon icon-download" />
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    renderListini() {
        const { attributes } = this.props;

        return (
            <table className="table table-striped table-striped-alt table-hover table-dense">
                <thead>
                    <tr>
                        <SortableTableHeader
                            attr="codice_listino"
                            onClick={this.toggleSort}
                            sorting={this.getSorting()}
                            headerStyle={{ width: '30px' }}
                        >
                            <Trans id="listino:code" />
                        </SortableTableHeader>
                        <SortableTableHeader
                            attr="codice_listino_prod"
                            onClick={this.toggleSort}
                            sorting={this.getSorting()}
                            headerStyle={{ width: '50px' }}
                        >
                            <Trans id="listino:code:prod" />
                        </SortableTableHeader>
                        <SortableTableHeader
                            attr="descrizione_listino"
                            onClick={this.toggleSort}
                            sorting={this.getSorting()}
                            headerStyle={{ width: '100px' }}
                        >
                            <Trans id="listino:descrizione" />
                        </SortableTableHeader>
                        <SortableTableHeader
                            attr="data_listino"
                            onClick={this.toggleSort}
                            sorting={this.getSorting()}
                            headerStyle={{ width: '80px' }}
                        >
                            <Trans id="listino:date" />
                        </SortableTableHeader>
                        <SortableTableHeader
                            attr="data_validita"
                            onClick={this.toggleSort}
                            sorting={this.getSorting()}
                            headerStyle={{ width: '80px' }}
                        >
                            <Trans id="data validita" />
                        </SortableTableHeader>
                        <SortableTableHeader
                            attr="codice_revisione"
                            onClick={this.toggleSort}
                            sorting={this.getSorting()}
                            headerStyle={{ width: '20px' }}
                        >
                            <Trans id="codice revisione" />
                        </SortableTableHeader>
                        <SortableTableHeader
                            attr="descrizione_revisione"
                            onClick={this.toggleSort}
                            sorting={this.getSorting()}
                            headerStyle={{ width: '50px' }}
                        >
                            <Trans id="descrizione revisione" />
                        </SortableTableHeader>
                        <SortableTableHeader
                            attr="data_validita_rev"
                            onClick={this.toggleSort}
                            sorting={this.getSorting()}
                            headerStyle={{ width: '80px' }}
                        >
                            <Trans id="data validita revisione" />
                        </SortableTableHeader>
                    </tr>
                </thead>
                <tbody>
                    {attributes.map((listino, i) => (
                        <tr key={i}>
                            <td>{listino.codice_listino}</td>
                            <td>{listino.codice_listino_prod}</td>
                            <td>
                                <LimitText limit={40} text={listino.descrizione_listino} />
                            </td>
                            <td>{format(parse(listino.data_listino), 'DD/MM/YYYY')}</td>
                            <td>{format(parse(listino.data_validita), 'DD/MM/YYYY')}</td>
                            <td>{listino.codice_revisione}</td>
                            <td>
                                <LimitText limit={40} text={listino.descrizione_revisione} />
                            </td>
                            <td>{format(parse(listino.data_validita_rev), 'DD/MM/YYYY')}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    getFileThumbnailPath(fileName) {
        return ` http://stage-dev.domuspartes.ildatapool.net/assets_thb/${fileName}.jpg`;
    }

    renderImageList() {
        const {
            onClick,
            showPreview,
            category
            // toggleItem,
            // selected
        } = this.props;

        const isNotMobile = window.innerWidth >= 640;

        if (showPreview) {
            return (
                <div>
                    {this.getFilteredAssetsAttributes().map((attr, i) => {
                        // console.log(attr);

                        return (
                            <div key={i} className="article-data-item">
                                <div
                                    className="article-details-img-wrapper c-hand"
                                    style={{
                                        height: '140px'
                                    }}
                                >
                                    <img
                                        className="img-responsive"
                                        src={this.getFileThumbnailPath(attr.filename)}
                                        alt={attr.label}
                                        onClick={() => onClick(i)}
                                        style={{
                                            maxHeight: '140px',
                                            margin: 'auto auto'
                                        }}
                                    />
                                </div>
                                <div style={{ margin: 0 }}>
                                    <div
                                        className="text-primary"
                                        title={getFileName(attr.filename)}
                                        style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        {getFileName(attr.filename)}
                                    </div>
                                    <div>
                                        <div className="float-left">
                                            <span>
                                                <Trans id="ext" />: {attr.ext}
                                                <br />
                                                {attr.size && (
                                                    <>
                                                        <Trans id="weight" />:{' '}
                                                        {filesize(attr.size, {
                                                            separator: ',',
                                                            base: 10
                                                        })}
                                                    </>
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="float-right">
                                        <a
                                            className="btn btn-primary"
                                            title="Download"
                                            href={attr.src}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <i className="icon icon-download" />
                                        </a>
                                    </div>
                                    <div
                                        style={{
                                            clear: 'both',
                                            paddingTop: '5px',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        <LimitText
                                            limit={20}
                                            text={attr.description !== '-' ? attr.description : ''}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        }

        if (isNotMobile) {
            return (
                <table className="table table-striped table-striped-alt table-hover table-dense">
                    <thead>
                        <tr>
                            <th style={{ width: '52px' }} />
                            <SortableTableHeader
                                attr="label"
                                onClick={this.toggleSort}
                                sorting={this.getSorting()}
                                headerStyle={{ width: '310px' }}
                            >
                                <Trans id={category.classLabel} />
                            </SortableTableHeader>
                            {/* <SortableTableHeader
                                attr="ext"
                                onClick={this.toggleSort}
                                sorting={this.getSorting()}
                                headerStyle={{ width: '80px' }}
                            >
                                <Trans id="ext" />
                            </SortableTableHeader>
                            <SortableTableHeader
                                attr="size"
                                onClick={this.toggleSort}
                                sorting={this.getSorting()}
                                headerStyle={{ width: '80px' }}
                            >
                                <Trans id="weight" />
                            </SortableTableHeader> */}
                            <SortableTableHeader
                                attr="filename"
                                onClick={this.toggleSort}
                                sorting={this.getSorting()}
                                headerStyle={{ width: '200px' }}
                            >
                                <Trans id="file:name" />
                            </SortableTableHeader>
                            <SortableTableHeader
                                attr="description"
                                onClick={this.toggleSort}
                                sorting={this.getSorting()}
                                headerStyle={{ width: '380px' }}
                            >
                                <Trans id="description" />
                            </SortableTableHeader>
                            <th style={{ width: '120px' }} className="text-center">
                                <Trans id="download" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.getFilteredAssetsAttributes().map((attr, i) => (
                            <tr key={i}>
                                <td>
                                    <span
                                        className="btn btn-action btn-link"
                                        onClick={() => onClick(i)}
                                    >
                                        <i className="icon icon-search" />
                                    </span>
                                </td>
                                <td>
                                    <LimitText
                                        limit={30}
                                        text={attr.label}
                                        textClass="tooltip-right"
                                    />
                                </td>
                                {/* <td>{attr.ext}</td>
                                <td>
                                    {attr.size
                                        ? filesize(attr.size, {
                                              separator: ',',
                                              base: 10
                                          })
                                        : '-'}
                                </td> */}
                                <td>
                                    <LimitText limit={40} text={getFileName(attr.filename)} />
                                </td>
                                <td>
                                    <LimitText limit={100} text={attr.description} />
                                </td>
                                <td className="text-center">
                                    <a
                                        className="btn btn-action btn-link"
                                        title="Download"
                                        href={attr.src}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="icon icon-download" />
                                    </a>
                                    {/* <span className="form-group">
                                        <label className="form-checkbox">
                                            <input
                                                type="checkbox"
                                                checked={includes(
                                                    selected,
                                                    attr.key
                                                )}
                                                onChange={() =>
                                                    toggleItem(attr.key)
                                                }
                                            />
                                            <i className="form-icon" />
                                        </label>
                                    </span> */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        }

        return (
            <table className="table table-striped table-striped-alt table-hover table-dense">
                <thead>
                    <tr>
                        <th style={{ width: '120px' }} className="text-center">
                            <Trans id="download" />
                        </th>
                        <SortableTableHeader
                            attr="filename"
                            onClick={this.toggleSort}
                            sorting={this.getSorting()}
                            headerStyle={{ width: '420px' }}
                        >
                            <Trans id="file:name" />
                        </SortableTableHeader>
                        <SortableTableHeader
                            attr="label"
                            onClick={this.toggleSort}
                            sorting={this.getSorting()}
                            headerStyle={{ width: '310px' }}
                        >
                            <Trans id={category.classLabel} />
                        </SortableTableHeader>
                        <SortableTableHeader
                            attr="ext"
                            onClick={this.toggleSort}
                            sorting={this.getSorting()}
                            headerStyle={{ width: '80px' }}
                        >
                            <Trans id="ext" />
                        </SortableTableHeader>
                        <SortableTableHeader
                            attr="size"
                            onClick={this.toggleSort}
                            sorting={this.getSorting()}
                            headerStyle={{ width: '80px' }}
                        >
                            <Trans id="weight" />
                        </SortableTableHeader>
                    </tr>
                </thead>
                <tbody>
                    {this.getFilteredAssetsAttributes().map((attr, i) => (
                        <tr key={i}>
                            <td className="text-center">
                                <a
                                    className="btn btn-action btn-link"
                                    title="Download"
                                    href={attr.src}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="icon icon-download" />
                                </a>
                            </td>
                            <td>
                                <LimitText limit={60} text={getFileName(attr.filename)} />
                            </td>
                            <td>
                                <LimitText limit={30} text={attr.label} textClass="tooltip-right" />
                            </td>
                            <td>{attr.ext}</td>
                            <td>
                                {attr.size
                                    ? filesize(attr.size, {
                                          separator: ',',
                                          base: 10
                                      })
                                    : '-'}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    // renderFeaturesList() {
    //     const { attributes } = this.props;

    //     return (
    //         <table className="table table-striped table-striped-alt table-hover table-article-info">
    //             <tbody>
    //                 {attributes.map((feature, i) => (
    //                     <tr key={i}>
    //                         <td>{feature.value}</td>
    //                         <td>
    //                             <strong>{feature.values[0].value}</strong>
    //                         </td>
    //                     </tr>
    //                 ))}
    //             </tbody>
    //         </table>
    //     );
    // }

    renderList() {
        const { type } = this.props;

        switch (type) {
            case constants.SECTION_TYPE_FILES:
                return this.renderFileList();
            case constants.SECTION_TYPE_IMAGES:
                return this.renderImageList();
            case constants.SECTION_TYPE_LISTINI:
                return this.renderListini();
            default:
                return this.renderTextList();
        }
    }

    render() {
        const { attributes } = this.props;

        // console.log(attributes);

        return attributes.length === 0 ? this.renderEmpty() : this.renderList();
    }
}

ItemDataCategoryTable.propTypes = {
    selectedFilter: PropTypes.string,
    attributes: PropTypes.array,
    category: PropTypes.object,
    isRepeatable: PropTypes.bool,
    onClick: PropTypes.func,
    showPreview: PropTypes.bool,
    type: PropTypes.string
};

ItemDataCategoryTable.defaultProps = {
    attributes: []
};

export default withI18n()(ItemDataCategoryTable);
