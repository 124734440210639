import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Motion, spring, presets } from 'react-motion';

import ArticleDetails from './ArticleDetails';

export default class ArticleDetailsWrapper extends Component {
    getStyle() {
        if (this.props.open) {
            return {
                minHeight: spring(480, presets.gentle)
            };
        }

        return {
            minHeight: spring(0)
        };
    }

    render() {
        const { open, tdClass, flexLayout } = this.props;

        const TrTag = flexLayout ? 'div' : 'tr';
        const TdTag = flexLayout ? 'div' : 'td';

        return (
            <TrTag className={cx('article-row-details', { open })}>
                <TdTag colSpan="99" className={tdClass}>
                    <Motion style={this.getStyle()} defaultStyle={{ minHeight: 0 }}>
                        {interpolatedStyle => (
                            <div
                                className={cx('article-row-details-content', {
                                    open
                                })}
                                style={interpolatedStyle}
                            >
                                {open ? <ArticleDetails {...this.props} /> : <div />}
                            </div>
                        )}
                    </Motion>
                </TdTag>
            </TrTag>
        );
    }
}

ArticleDetailsWrapper.propTypes = {
    open: PropTypes.bool,
    tdClass: PropTypes.string
};
