import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';

import DropdownListFilterWrapper from './utils/DropdownListFilterWrapper';
import { actions } from '../actions/filtersActions';
import * as c from '../constants';
import { getOptionLabelLocalized, getLocaleFromLanguageCode } from '../intl-helpers';

function getListiniLabel(language) {
    return function(option) {
        const baseLabel = getOptionLabelLocalized(language)(option);

        if (option.extra && option.extra.codice_revisione !== '00') {
            const locale = getLocaleFromLanguageCode(language);

            const descrizione = get(
                option.extra,
                `descrizione_revisione.${locale}`,
                option.extra.codice_revisione
            );

            return `${baseLabel} - ${descrizione}`;
        }

        return baseLabel;
    };
}
export class ArticleTableFilters extends Component {
    render() {
        const {
            // catalogFilter,
            i18n,
            lineFilter,
            modelFilter,
            seriesFilter,
            listiniFilter,
            language
        } = this.props;

        const seriesCount = seriesFilter.options.count();
        const linesCount = lineFilter.options.count();
        const modelsCount = modelFilter.options.count();

        const hasAnyFilter = seriesCount + linesCount + modelsCount > 0;

        if (hasAnyFilter === false) {
            return null;
        }

        return (
            <div className="columns bg-secondary p-2 table-filters-container">
                {listiniFilter.options.count() > 0 && (
                    <div className="column col-3">
                        <DropdownListFilterWrapper
                            filter={listiniFilter}
                            placeholder={i18n._('filter:listini')}
                            itemToString={getListiniLabel(language)}
                            onChange={item =>
                                this.props.dispatch(actions.setFilterValue(c.LISTINI_FILTER, item))
                            }
                            multiple={true}
                        />
                    </div>
                )}
                {seriesCount > 0 && (
                    <div className="column col-3">
                        <DropdownListFilterWrapper
                            filter={seriesFilter}
                            placeholder={i18n._('filter:series')}
                            itemToString={getOptionLabelLocalized(language)}
                            onChange={item =>
                                this.props.dispatch(actions.setFilterValue(c.SERIES_FILTER, item))
                            }
                            multiple={true}
                        />
                    </div>
                )}
                {linesCount > 0 && (
                    <div className="column col-3">
                        <DropdownListFilterWrapper
                            filter={lineFilter}
                            placeholder={i18n._('filter:line')}
                            itemToString={getOptionLabelLocalized(language)}
                            onChange={item =>
                                this.props.dispatch(actions.setFilterValue(c.LINE_FILTER, item))
                            }
                            multiple={true}
                        />
                    </div>
                )}
                {modelsCount > 0 && (
                    <div className="column col-3">
                        <DropdownListFilterWrapper
                            filter={modelFilter}
                            placeholder={i18n._('filter:model')}
                            useVirtualList={false}
                            itemToString={getOptionLabelLocalized(language)}
                            onChange={item =>
                                this.props.dispatch(actions.setFilterValue(c.MODEL_FILTER, item))
                            }
                            multiple={true}
                        />
                    </div>
                )}
                {/* {seriesCount + linesCount + modelsCount === 0 && (
                    <div className="column col-12" style={{ height: '37px' }} />
                )} */}
            </div>
        );
    }
}

ArticleTableFilters.propTypes = {
    // catalogFilter: PropTypes.object.isRequired,
    i18n: PropTypes.object,
    lineFilter: PropTypes.object.isRequired,
    modelFilter: PropTypes.object.isRequired,
    seriesFilter: PropTypes.object.isRequired,
    language: PropTypes.string
};

export default withI18n()(ArticleTableFilters);
