import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withI18n, Trans } from '@lingui/react';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { parse, format } from 'date-fns';

import { deleteUtente, updateUser } from '../api';
import SelectGroup from './select/SelectGroup';
// import { getUtente } from '../api';

function localizeDate(date) {
    if (!date) {
        return '-';
    }

    return format(parse(date), 'DD/MM/YYYY HH:mm:ss');
}

export class UserDetailsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user_group_id: props.user.group_id,
            isUpdatingUserGroup: false
        };
    }

    // async componentDidMount() {
    //     const res = await getUtente(this.props.userId);

    //     this.setState({
    //         isFetching: false,
    //         user: res.data
    //     });
    // }

    handleDelete = () => {
        const { i18n } = this.props;

        Swal.fire({
            type: 'warning',
            text: i18n._('confirm:delete:user'),
            showCancelButton: true,
            confirmButtonText: i18n._('confirm'),
            cancelButtonText: i18n._('cancel')
        }).then(res => {
            if (res.value === true) {
                deleteUtente(this.props.user.id).then(res => {
                    toast(i18n._('User deleted'), {
                        position: 'bottom-right',
                        type: toast.TYPE.SUCCESS
                    });

                    this.props.onClose();

                    setTimeout(() => {
                        this.props.onDeleteUtente(this.props.user.id);
                    }, 500);
                });
            }
        });
    };

    updateGroup = async () => {
        this.setState({
            isUpdatingUserGroup: true
        });

        const res = await updateUser(this.props.user.id, {
            group_id: this.state.user_group_id ? this.state.user_group_id.value : null,
            preferences: this.props.user.preferences || []
        });

        // TODO: gestire errori

        const msg = this.props.i18n._('user:userGroup:updated');

        toast(msg, {
            position: 'bottom-right',
            type: toast.TYPE.SUCCESS
        });

        this.props.onUpdateUserGroup(this.props.user.id, res.data.data);

        this.setState({
            isUpdatingUserGroup: false
        });
    };

    render() {
        const { onClose, onEnableUtente, onToggleLockUtente, user } = this.props;
        // const { isFetching } = this.state;

        return (
            <div className="modal active" id="user-details-modal-id">
                <span className="modal-overlay" aria-label="Close" />
                <div className="modal-container" style={{ maxWidth: '800px' }}>
                    <div className="modal-header">
                        <div className="modal-title text-center h5">
                            {user.titolo} {user.nome} {user.cognome}
                            <span
                                className="btn btn-clear float-right"
                                aria-label="Close"
                                onClick={onClose}
                            />
                        </div>
                    </div>
                    <div className="modal-body" id="user-details-modal">
                        <div className="content">
                            <div className="container">
                                <div className="columns">
                                    <div className="column col-4 text-right">
                                        <Trans id="title" />:
                                    </div>
                                    <div className="column col-8 text-bold">{user.titolo}</div>
                                    <div className="column col-4 text-right">
                                        <Trans id="name" />:
                                    </div>
                                    <div className="column col-8 text-bold">{user.nome}</div>
                                    <div className="column col-4 text-right">
                                        <Trans id="surname" />:
                                    </div>
                                    <div className="column col-8 text-bold">{user.cognome}</div>
                                    <div className="column col-4 text-right">Email:</div>
                                    <div className="column col-8 text-bold">{user.email}</div>
                                    <div className="column col-4 text-right">
                                        <Trans id="company" />:
                                    </div>
                                    <div className="column col-8 text-bold">
                                        {user.ragione_sociale}
                                    </div>
                                    <div className="column col-4 text-right">
                                        <Trans id="group" />:
                                    </div>
                                    <div className="column col-4 text-bold">
                                        <SelectGroup
                                            selectedItem={this.state.user_group_id}
                                            placeholder={this.props.i18n._('filter:group')}
                                            inputClassName="form-input"
                                            onChange={option =>
                                                this.setState({
                                                    user_group_id: option
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="column col-2">
                                        <button
                                            className="btn btn-primary"
                                            onClick={this.updateGroup}
                                            disabled={this.state.isUpdatingUserGroup}
                                        >
                                            {this.state.isUpdatingUserGroup ? (
                                                <Trans id="Please wait" />
                                            ) : (
                                                <Trans id="confirm:update" />
                                            )}
                                        </button>
                                    </div>
                                    <div className="column col-4 text-right">
                                        <Trans id="activity" />:
                                    </div>
                                    <div className="column col-8 text-bold">{user.attivita}</div>
                                    <div className="column col-4 text-right">
                                        <Trans id="province" />:
                                    </div>
                                    <div className="column col-8 text-bold">
                                        {user.nome_provincia}
                                    </div>
                                    <div className="column col-12 my-2" />
                                    <div className="column col-4 text-right">
                                        <Trans id="Registration date" />:
                                    </div>
                                    <div className="column col-8 text-bold">
                                        {localizeDate(user.created_at)}
                                    </div>
                                    <div className="column col-4 text-right">
                                        <Trans id="enabled" />:{' '}
                                        <label className="form-switch form-switch-inline form-switch-slim">
                                            <input
                                                type="checkbox"
                                                checked={user.enabled}
                                                disabled={user.enabled}
                                                onChange={() => onEnableUtente(user.id)}
                                            />
                                            <i className="form-icon" />
                                        </label>
                                        <Trans id="at" />:
                                    </div>
                                    <div className="column col-8 text-bold">
                                        {localizeDate(user.enabled_at)}
                                    </div>
                                    <div className="column col-4 text-right">
                                        <Trans id="blocked" />:{' '}
                                        <label className="form-switch form-switch-inline form-switch-slim">
                                            <input
                                                type="checkbox"
                                                checked={user.locked}
                                                onChange={() => onToggleLockUtente(user.id)}
                                            />
                                            <i className="form-icon" />
                                        </label>
                                        <Trans id="at" />:
                                    </div>
                                    <div className="column col-8 text-bold">
                                        {localizeDate(user.locked_at)}
                                    </div>
                                    <div className="column col-4 text-right">
                                        <Trans id="last:login" />:
                                    </div>
                                    <div className="column col-8 text-bold">
                                        {localizeDate(user.logged_at)}
                                    </div>
                                    <div className="column col-12 text-right">
                                        <button
                                            className="btn btn-error"
                                            onClick={this.handleDelete}
                                        >
                                            <Trans id="Delete user" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

UserDetailsModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    onEnableUtente: PropTypes.func.isRequired,
    onToggleLockUtente: PropTypes.func.isRequired
};

export default withI18n()(UserDetailsModal);
