import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link, NavLink, Route /*, withRouter*/ } from 'react-router-dom';
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';

import HeaderSearch from '../containers/HeaderSearch';
// import NavLink from './NavLink';
import { selectors } from '../reducers/userReducer';
import { selectors as appSelectors } from '../reducers/appReducer';
import { authLogout /*, updateUser*/ } from '../actions/userActions';
import { changeLanguage } from '../actions/appActions';
import { getAssetURL } from '../api';
// import ChangeLanguageModal from './ChangeLanguageModal';
// import { getLocaleFromLanguageCode } from '../intl-helpers';

export class Header extends Component {
    handleLogout = e => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        this.props.logout();
    };

    getUserName() {
        const { user } = this.props;

        if (user === null) {
            return '';
        }

        return `${user.nome} ${user.cognome}`;
    }

    render() {
        const { user, /*langCode,*/ appName } = this.props;

        const adminRoutes = [
            <MenuItem
                key="users"
                onClick={() => {
                    this.props.history.push('/app/users');
                }}
            >
                <NavLink to="/app/users" className="c-hand" activeClassName="active">
                    <Trans id="User management" />
                </NavLink>
            </MenuItem>,
            <MenuItem
                key="userGroups"
                onClick={() => {
                    this.props.history.push('/app/userGroups');
                }}
            >
                <NavLink to="/app/userGroups" className="c-hand" activeClassName="active">
                    <Trans id="User Groups management" />
                </NavLink>
            </MenuItem>,
            <MenuItem
                key="processes"
                onClick={() => {
                    this.props.history.push('/app/processes');
                }}
            >
                <NavLink to="/app/processes" className="c-hand" activeClassName="active">
                    <Trans id="Brand management" />
                </NavLink>
            </MenuItem>,
            <MenuItem
                key="customize"
                onClick={() => {
                    this.props.history.push('/app/customize');
                }}
            >
                <NavLink to="/app/customize" className="c-hand" activeClassName="active">
                    <Trans id="Logo management" />
                </NavLink>
            </MenuItem>
        ];

        return (
            <header className="navbar navbar-fixed">
                <section className="navbar-section">
                    <Link to="/app">
                        <img
                            src={getAssetURL(`logo_header.png?t=${new Date().getTime()}`)}
                            alt="Datapool Logo"
                            height={40}
                            style={{ margin: '4px 2px 0 0' }}
                        />
                    </Link>{' '}
                    <Link to="/app" className="navbar-brand c-hand">
                        {appName}
                    </Link>
                </section>
                <Route exact={true} path="/app" component={HeaderSearch} />
                <div className="navbar-section">
                    <Menu
                        className="menu"
                        menuButton={
                            <MenuButton className="btn btn-primary">
                                <i className="icon icon-people" /> {this.getUserName()}{' '}
                                <i className="icon icon-caret" />
                            </MenuButton>
                        }
                    >
                        <SubMenu
                            label={() => (
                                // eslint-disable-next-line
                                <a>
                                    <Trans id="My profile" />
                                </a>
                            )}
                            direction="left"
                            offsetX={1}
                        >
                            <MenuItem
                                onClick={() => {
                                    this.props.history.push('/app/profile');
                                }}
                            >
                                <NavLink
                                    to="/app/profile"
                                    className="c-hand"
                                    activeClassName="active"
                                >
                                    <Trans id="preferences" />
                                </NavLink>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    this.props.history.push('/app/customize-table');
                                }}
                            >
                                <NavLink
                                    to="/app/customize-table"
                                    className="c-hand"
                                    activeClassName="active"
                                >
                                    <Trans id="customize table" />
                                </NavLink>
                            </MenuItem>
                        </SubMenu>
                        {user && user.ruolo === 'admin' ? adminRoutes : []}
                        <MenuItem onClick={this.handleLogout}>
                            {/* eslint-disable-next-line */}
                            <a className="c-hand">
                                <Trans id="logout" />
                            </a>
                        </MenuItem>
                    </Menu>
                    {/* <div className="dropdown dropdown-right dropdown-nav">
                        <span className="btn btn-primary dropdown-toggle c-hand" tabIndex="0">
                            <i className="icon icon-people" /> {this.getUserName()}{' '}
                            <i className="icon icon-caret" />
                        </span>
                        <ul className="menu">
                            <li className="menu-item">
                                <NavLink
                                    to="/app/profile"
                                    className="c-hand"
                                    activeClassName="active"
                                >
                                    <Trans id="profile" />
                                </NavLink>
                            </li>
                            {user && user.ruolo === 'admin' && (
                                <>
                                    <li className="menu-item">
                                        <NavLink
                                            to="/app/users"
                                            className="c-hand"
                                            activeClassName="active"
                                        >
                                            <Trans id="User management" />
                                        </NavLink>
                                    </li>
                                    <li className="menu-item">
                                        <NavLink
                                            to="/app/userGroups"
                                            className="c-hand"
                                            activeClassName="active"
                                        >
                                            <Trans id="User Groups management" />
                                        </NavLink>
                                    </li>
                                    <li className="menu-item">
                                        <NavLink
                                            to="/app/processes"
                                            className="c-hand"
                                            activeClassName="active"
                                        >
                                            <Trans id="Brand management" />
                                        </NavLink>
                                    </li>
                                    <li className="menu-item">
                                        <NavLink
                                            to="/app/customize"
                                            className="c-hand"
                                            activeClassName="active"
                                        >
                                            <Trans id="customization" />
                                        </NavLink>
                                    </li>
                                    <li className="menu-item">
                                        <NavLink
                                            to="/app/customize-table"
                                            className="c-hand"
                                            activeClassName="active"
                                        >
                                            <Trans id="customize table" />
                                        </NavLink>
                                    </li>
                                </>
                            )}
                            <li className="menu-item">
                                <a className="c-hand" href="/app/login" onClick={this.handleLogout}>
                                    <Trans id="logout" />
                                </a>
                            </li>
                        </ul>
                    </div> */}
                </div>
                {/*this.state.showChangeLanguageModal && (
                    <ChangeLanguageModal
                        onClose={this.closeModal}
                        langCode={langCode}
                        user={user}
                        onChangeLanguage={this.handleChangeLanguage}
                    />
                )*/}
            </header>
        );
    }
}

Header.propTypes = {
    fullTextFilter: PropTypes.object
};

function mapStateToProps(state) {
    return {
        user: selectors.getUser(state),
        // langCode: appSelectors.getLanguage(state),
        appName: appSelectors.getAppName(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        logout() {
            dispatch(authLogout());
        },
        changeLanguage(lang) {
            dispatch(changeLanguage(lang));
        }
        /*updateUser(payload) {
            dispatch(updateUser(payload));
        }*/
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
