import React, { Component, Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import LoadingBar from 'react-redux-loading-bar';
import ResizeAware from 'react-resize-aware';

import Header from './Header';
import UsersList from '../containers/UsersList';
import UserGroupsList from './UserGroupsList';
import DataPool from './DataPool';
import UserProfile from './UserProfile';
import ErrorPage from './ErrorPage';
import ProcessesList from './ProcessesList';
import CustomizationPanel from './CustomizationPanel';
import CustomizeTablePanel from './CustomizeTablePanel';

class Grid extends Component {
    render() {
        return (
            <Fragment>
                <LoadingBar scope="itemsFetch" style={{ zIndex: 4, backgroundColor: '#FFC107' }} />
                <Header />
                <ResizeAware>
                    {({ width }) => (
                        <div className="content-area">
                            <Switch>
                                <Route
                                    path="/app"
                                    exact={true}
                                    render={() => <DataPool width={width} />}
                                />
                                <Route path="/app/users" component={UsersList} exact={true} />
                                <Route path="/app/userGroups" component={UserGroupsList} />
                                <Route path="/app/profile" component={UserProfile} />
                                <Route path="/app/processes" component={ProcessesList} />
                                <Route path="/app/customize" component={CustomizationPanel} />
                                <Route
                                    path="/app/customize-table"
                                    component={CustomizeTablePanel}
                                />
                                <Route component={ErrorPage} />
                            </Switch>
                        </div>
                    )}
                </ResizeAware>
            </Fragment>
        );
    }
}

export default Grid;
