import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/react';
import cx from 'classnames';

import { getIntlNumberFormat } from '../intl-helpers';
import Paginator from './Paginator';

const intl = getIntlNumberFormat();

class ArticlePagination extends Component {
    render() {
        const { layout, pages, total, itemTranslationKey, currentPage, onPageChange } = this.props;

        const leftColClass = layout === 'wide' ? 'col-6' : 'col-12 col-ml-auto';
        const rightColClass = layout === 'wide' ? 'col-6' : 'col-12';

        return (
            <div className="columns" style={{ alignContent: 'flex-end' }}>
                <div className={`column ${leftColClass}`}>
                    <Paginator
                        currentPage={currentPage}
                        pages={pages}
                        onPageChange={onPageChange}
                        containerClassName={cx('pagination', {
                            'pagination-right': layout === 'compact'
                        })}
                    />
                </div>
                <div className={`column ${rightColClass} mt-2 text-right`}>
                    {total > 0 && (
                        <div>
                            <Trans
                                id={itemTranslationKey}
                                components={[<strong />]}
                                values={{ items: intl.format(total) }}
                            />{' '}
                            <Trans
                                id="pagination:pages"
                                components={[<strong />]}
                                values={{ pages }}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

ArticlePagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    itemTranslationKey: PropTypes.string.isRequired,
    layout: PropTypes.oneOf(['compact', 'wide'])
};

ArticlePagination.defaultProps = {
    layout: 'wide'
};

export default ArticlePagination;
