import format from 'date-fns/format';
import isObject from 'lodash/isObject';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import tail from 'lodash/tail';

import { getIntlNumberFormat } from '../intl-helpers';
import { formatKeyValue } from '../formatters';

const baseIntl = getIntlNumberFormat();

const numberIntl = getIntlNumberFormat('it-IT', {
    minimumFractionDigits: 4
});

export function getDetailValue(detail, prop, locale, productProperties, i18n) {
    // console.log(detail);
    // console.log(prop);

    if (typeof detail === 'undefined') {
        return null;
    }

    // FIXME: Sarebbe di type "collection" ma deve funzionare in maniera diversa dallo standard, come da richiesta
    if (prop.code === 'custom_classifications') {
        return detail.children
            .map(item => {
                return getDetailValue(
                    item,
                    productProperties[item.property],
                    locale,
                    productProperties
                );
            })
            .join('<br/>');
    }

    if (prop.type === 'collection') {
        if (detail.value === null && detail.children && detail.children.length > 0) {
            return detail.children
                .map(item => {
                    // Ricorsivo
                    return getDetailValue(
                        item,
                        productProperties[item.property],
                        locale,
                        productProperties
                    );
                })
                .join(' / ');
        }

        if (detail.value) {
            // TODO: da verificare con altri tipi di dato...
            return sortBy(detail.value, 'descrizione')
                .map(v => (isObject(v.descrizione) ? v.descrizione[locale] : v.descrizione))
                .join(' / ');
        }
    }

    if (detail.value === null) {
        return null;
    }

    // TODO: da rimuovere non appena il mode "prezzo" verra pubblicato in produzione
    if (prop.code === 'prezzo_listino' || prop.code === 'prezzo_articolo_spallettizzato') {
        return numberIntl.format(detail.value);
    }

    if (prop.type === 'boolean') {
        const s = detail.value === false ? i18n._('no') : i18n._('yes');

        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    if (prop.type === 'single_option') {
        return detail.value.descrizione;
    }

    if (prop.mode === 'marca') {
        return detail.value.sigla_marca;
    }

    if (prop.mode === 'prezzo') {
        return numberIntl.format(detail.value);
    }

    if (prop.type === 'float' || prop.type === 'float_metric_value') {
        return baseIntl.format(detail.value);
    }

    if (prop.type === 'datetime' || prop.type === 'date') {
        return format(detail.value, 'DD/MM/YYYY');
    }

    if (prop.type === 'key_value_collection') {
        return formatKeyValue(detail.value);
    }

    if (prop.mode === 'territorio_collection') {
        return detail.value
            .map(record => record.label[locale].value)
            .sort()
            .join(' / ');
    }

    // FIXME: Sarebbe di type "choice" ma deve funzionare in maniera diversa dallo standard, come da richiesta
    if (prop.code === 'sigla_marca') {
        return detail.value.code;
    }

    if (prop.type === 'choice') {
        return isObject(detail.value.label)
            ? get(detail.value.label, locale, detail.value.label.key)
            : detail.value.label;
    }

    if (prop.type === 'localized_string' || prop.type === 'localized_text') {
        return get(detail.value, locale, detail.value.key);
    }

    if (prop.type === 'free_choice') {
        return get(detail.value.label, locale, detail.value.label.key);
    }

    if (prop.type === 'classification') {
        const classificationTree = detail.value.map(leaf => {
            return get(leaf, `label.${locale}`, `[${leaf.code}]`);
        });

        const classificationLeafs = tail(classificationTree).join(' / ');

        return `${classificationTree[0]}: ${classificationLeafs}`;
    }

    if (prop.type === 'etim_class') {
        return get(detail.value.label, locale, detail.value.name);
    }

    return detail.value;
}
